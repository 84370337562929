import React from "react"
import Typography from '@material-ui/core/Typography'
import { makeStyles, Hidden, Button } from "@material-ui/core"
import theme from "./theme"
import OnToTwo from "../images/OneToTwo.mp4"
import phones from "../images/phones.png"
import heroSvg from "../images/heroSvg.svg"
import { trackOutBoundLink } from './analytics'
import { appStoreUrl } from "./constants"

const heroCommonStyle = {
  display: "grid"
}

const heroDesktop = {
gridTemplateColumns: "repeat(24, 1fr)",
gridTemplateAreas: '"title image"',
}

const heroMobile = {
  gridTemplateColumns: "1fr",
  gridTemplateAreas: '"title"'
}

const useStyles = makeStyles({
  heroSection: {
    ...heroCommonStyle,
    [theme.breakpoints.up("md")]: heroDesktop,
    [theme.breakpoints.down("sm")]: heroMobile
  },
  heroSectionTitle: {
    [theme.breakpoints.down('sm')]: {
      justifyItems: "center",
      textAlign: "center"
    }
  },
  heroSectionText: {
    fontWeight: 500,
    fontSize: "1.3vw",
    opacity: "0.9",
    color: theme.palette.background.primaryLight,
    [theme.breakpoints.up('lg')]: {
      fontSize: "1.125rem"
    }
  }
})

const Hero = () => {

  const classes = useStyles()

  return (
    <div className={classes.heroSection}>
      <div className={classes.heroSectionTitle}
        style={{
          display: "grid",
          gridAutoFlow: "row",
          gridArea: "title",
          gridColumn: "1 / 16",
          gridRow: "1 / 2",
          }}>
        <Typography
          variant="h3"
          component="h1"
          style={{
            marginTop: "150px",
            color: theme.palette.background.primaryLight
          }}>

          תעבור תאוריה.

        </Typography>
        <Typography
          variant="h3"
          component="h2"
          style={{ color: theme.palette.background.primaryLight }}>

          קח ערך לחיים.

        </Typography>
        <Typography
          variant="body2"
          style={{
            marginTop: "60px",
            fontSize: "1.2rem",
            color: theme.palette.background.primaryLight
          }}>

          אפליקציה שכיף ללמוד איתה

        </Typography>
        <Button
          variant="contained"
          href={appStoreUrl}
          onClick={() => trackOutBoundLink(appStoreUrl)}
        >

          נסה עכשיו

        </Button>
      </div>
      <div style={{
        gridArea: "image",
        gridRow: "1 / 2",
        gridColumn: "9 / 25",
        margin: "auto 0",
        maxWidth: "100%",
        }}>
        <Hidden smDown>
          <div style={{ position: "relative" }}>
            <img
              src={heroSvg}
              alt=""
              style={{
                width: "105%",
                paddingLeft: "25px",
              }}
            />
            <Typography className={classes.heroSectionText} 
              style={{
                position: "absolute",
                top: "1.8%",
                left: "65.2%"
              }}>
                לוח תמרורים רשמי
            </Typography>
            <Typography className={classes.heroSectionText}
              style={{
                position: "absolute",
                top: "28.8%",
                left: "1.9%"
              }}>
                ספר לימוד מלא
            </Typography>
            <Typography className={classes.heroSectionText}
              style={{
                position: "absolute",
                top: "69%",
                left: "76.5%"
              }}>
                מבחני תאוריה אמיתיים           
            </Typography>
            <Typography className={classes.heroSectionText}
              style={{
                position: "absolute",
                top: "92%",
                left: "12.5%"
              }}>
                מדריך לקבלת רשיון
            </Typography>
            <img
              src={phones}
              style={{
                position: "absolute",
                top: "6%",
                left: "29%",
                width: "38.6%",
                maxHeight: "434px",
                objectFit: "contain",
              }}
              alt=""
            />
            <video autoPlay={true} loop={true} muted={true} playsInline={true} alt="" 
            style={{
              position: "absolute",
              height: `22%`,
              width: "22%",
              top: "50%",
              left: "44.5%",
              objectFit: "contain",
            }}>
            <source src={OnToTwo} type="video/mp4" />
            </video>
          </div>
        </Hidden>
      </div>
    </div>
  )
}

export default Hero