import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import {
  Typography,
  Grid,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Container
} from "@material-ui/core"

import BgPattern from "../images/bgpattern.png"
import { makeStyles } from "@material-ui/core/styles"
import Fail from "../images/Fail.svg"
import Animations from "../images/Animations.svg"
import Brain from "../images/Brain.svg"
import Exam from "../images/Exam.svg"
import Map from "../images/Map.svg"
import Money from "../images/Money.svg"
import Stars from "../images/Stars.svg"
import theme from "../components/theme"
import { appStoreUrl, appStoreReviewURL } from "../components/constants"
import { trackOutBoundLink } from '../components/analytics'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
  },
  problemSection: {
    backgroundColor: theme.palette.background.primaryLight,
  },
  problemSectionHeader: {
    [theme.breakpoints.down('md')]: {
      textAlign: "center"
    },
  },
  benefitReverse: {
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  benefitSectionHeader: {
    [theme.breakpoints.down('sm')]: {
      textAlign: "center"
    }
  },
  benefitSectionContent: {
    [theme.breakpoints.down('sm')]: {
      textAlign: "justify"
    },
  },
}))

const Testimonial = props => {
  const { text, author } = props
  const classes = useStyles()
  return (
    <Card elevation={0}
      align="right"
      className={ classes.paper }
      style={{
        borderRadius: "16px",
        display: "flex",
      }}>
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <img
          src={Stars}
          alt=""
          style={{
            padding: "6px 0 30px",
            alignSelf: "flex-start"
          }}
        />
        <Typography
          variant="body1"
          style={{ paddingBottom: "50px" }}
        >
          {text}
        </Typography>

        <footer>
          <Typography
            variant="subtitle2"
            align="right"
            component="p"
          >
            {author}
          </Typography>
        </footer>
      </CardContent>
    </Card>
  )
}

const Problem = props => {
  const { title, children, image } = props
  const classes = useStyles()
  return (
    <Card elevation={1} style={{ width: "100%" }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={3}
          md={12}
          lg={3}
          align="center"
        >
          <img src={image} style={{ maxWidth: "100px" }} alt="" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={9}
          md={12}
          lg={9}
          container
          direction="column"
          justify="center"
          style={{ textAlign: "justify" }}
        >
          <CardHeader
            title={title}
            titleTypographyProps={{ variant: "h5", color: "primary" }}
            className={classes.problemSectionHeader}
          />
          <CardContent style={{ padding: "16px 32px 24px" }}>
            {children}
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  )
}

const Benefit = props => {
  const { title, children, image, reverse } = props
  const classes = useStyles()
  return (
    <Card elevation={0}
      style={{
        flexGrow: 1,
        backgroundColor: "transparent",
        padding: "24px 0"
      }}>
      <Grid container>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={5}
          alignItems="center"
          justify="center"
          className={ reverse ? classes.benefitReverse : null }
        >
          <img src={image} alt="" style={{ maxWidth: "50%" }} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          container
          direction="column"
          justify="center"
          className={classes.benefitSectionHeader}
        >
          <CardHeader
            title={title}
            titleTypographyProps={{ variant: "h6", color: "primary" }}
          />
          <CardContent className={classes.benefitSectionContent}>
            {children}
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  )
}

const IndexPage = ({ path }) => {

  return (
    <Layout showNav={false} path={path}>
      <SEO
        title="הכנה לתאוריה"
        description="אפליקציה שגורמת לך לרצות ללמוד תאוריה. אנימציות מבהירות את העקרונות ברגע. אותן השאלות בדיוק כמו במבחן האמיתי. אין פרסומות, אין היסחי דעת. קבל הכוונה דרך המוסדות השונים שבהם תצטרך לבקר כדי להוציא רשיון."
      />
      <Paper
        elevation={0}
        style={{
          backgroundColor: theme.palette.primary.main,
          backgroundImage: `url(${BgPattern})`,
          backgroundRepeat: "repeat-y",
          backgroundPosition: "99% 8%",
          padding: "25px 0",
        }}>
        <Container>
          <Hero />
        </Container>
      </Paper>
      <Paper
        elevation={0}
        square
        style={{
          backgroundColor: theme.palette.background.primaryLight,
          paddingBottom: "80px"
        }}>
        <Container>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              xs={12}
              container
              style={{
                justifyContent: "center"
              }}>
              <Typography
                variant="h3"
                align="center"
                color="textSecondary"
                style={{ paddingTop: "32px", }}
              >
                הבעיות העיקריות של כל לומדי הנהיגה
              </Typography>
            </Grid>

            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              container
              style={{ alignItems: "stretch" }}
            >
              <Problem title={"לא מצליחים לקלוט את החומר"} image={Fail}>
                <Typography variant="body1">
                  מנסים להתרכז, מנסים להבין, הפרסומות קופצות, הדעת מוסחת.
                  מתחילים לפקפק ביכולת הקשב והריכוז שלכם. הבעיה לא בכם, הבעיה
                  בפרסומות.
                </Typography>
              </Problem>
            </Grid>

            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              container
            >
              <Problem title={"הרישיון עולה הרבה יותר מהצפוי"} image={Money}>
                <Typography variant="body1">
                  אנשים שלא למדו לעומק, אלא רק שיננו שאלות:
                </Typography>
                <Typography variant="body1">
                  - ניגשו למבחן התאוריה יותר מפעם אחת
                </Typography>
                <Typography variant="body1">
                  - היו צריכים יותר שיעורים מעשיים
                </Typography>
                <Typography variant="body1">
                  - נכשלו בטסט עקב תכנון נסיעה לקוי
                </Typography>
                <Typography
                  variant="body1"
                  style={{ textAlign: "right" }}
                >
                  כך מדווחים מורי הנהיגה
                </Typography>
              </Problem>
            </Grid>
          </Grid>
        </Container>
      </Paper>

      <Paper
        elevation={0}
        square
        style={{
          backgroundColor: theme.palette.background.prominent,
          paddingBottom: "24px"
        }}>
        <Container>
          <Grid
            item xs={12}
            container
            style={{
              justifyContent: "center"
            }}>
            <Typography
              variant="h3"
              align="center"
              color="textSecondary"
              style={{ padding: "32px 0 24px", }}>

              מה יש לנו שאין לאחרים

            </Typography>
          </Grid>

          <Grid item md={12} xs={12} container>
            <Benefit
              title="תבין לעומק בלי לקרוא הרבה"
              image={Animations}
              reverse
            >
              <Typography variant="body1">
                אנימציות מבהירות את העקרונות ברגע. הטקסט מוסיף הסבר קצר
                וקולע. תגיע יותר מוכן לנהיגה ותיגש לטסט בפחות שיעורים.
              </Typography>
            </Benefit>
          </Grid>

          <Grid item md={12} xs={12} container>
            <Benefit
              title="תתאמן על אמת"
              image={Exam}
            >
              <Typography variant="body1">
                תתרגל את אותן השאלות בדיוק עם אותן התשובות בדיוק תוך אותם הזמנים
                בדיוק כמו במבחן האמיתי.{" "}
              </Typography>
              <Typography variant="body1">
                השאלות באפליקציה נלקחו ממאגר השאלות של משרד התחבורה. המאגר כולל
                1200 שאלות שמהם מרכיבים את מבחני התאוריה האמיתיים.
              </Typography>
            </Benefit>
          </Grid>

          <Grid item md={12} xs={12} container>
            <Benefit
              title="אין הסחות דעת - יש לך שקט בראש"
              image={Brain}
              reverse
            >
              <Typography variant="body1">
                תתרכז בלימודים ולא בפרסומות. תהנה מעיצוב מיוחד שממקד את הראייה.
              </Typography>
              <Typography variant="body1">
                מקלה על אנשים עם הפרעות קשב וריכוז
              </Typography>
            </Benefit>
          </Grid>

          <Grid item md={12} xs={12} container>
            <Benefit
              title="נווט דרך המוסדות"
              image={Map}
            >
              <Typography variant="body1">
                קבל הכוונה דרך המוסדות השונים שבהם תצטרך לבקר כדי להוציא רשיון.
              </Typography>
              <Typography variant="body1">
                קבל הסבר קצר וקולע על הטופס הבא שאתה נדרש למלא. מטופס ירוק עד
                תשלום אגרת רשיון נהיגה קבוע.
              </Typography>
            </Benefit>
          </Grid>
        </Container>
      </Paper>

      <Paper elevation={0}
        square
        style={{
          backgroundColor: theme.palette.primary.main,
        }}>
        <Container>
          <Grid item xs={12} container style={{ justifyContent: "center" }}>
            <Typography
              variant="h6"
              align="center"
              style={{
                margin: "30px 0 60px",
                color: theme.palette.background.primaryLight
              }}>

              התלמידים אומרים עלינו

            </Typography>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6} lg={3} container>
              <Testimonial
                text="אפליקציה מעולה !! היחידה שנותנת לי באמת דחף ללמוד לתיאוריה במיוחד לילדים עם קשב וריכוז ממליצה לכל אחד ואחד !!!!"
                author="שיראל בלילטי"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={3} container>
              <Testimonial
                text="אפליקציה נוחה, קלילה וידידותית לקהל המשתמש. אני מאוד מרוצה ממנה! הכי טובה בעיניי בשוק ללימוד תיאוריה. היוצר גם מאוד חברותי, זמין ואכפתי. רמה גבוהה של שירות."
                author="Wendy_yeboah"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={3} container>
              <Testimonial
                text="מאוד אהבתי שיש מגוון רחב של הסברים בספר לימוד ומגוון מאוד רחב של מבחני סימולציה .
                  זה נותן ללומד הרבה מאוד אפשרויות וזה יותר מקדם אותו למטרה"
                author="אמילי פרנקל"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={3} container>
              <Testimonial
                text="עזר לי מאוד בכל תהליך הרישיון ליווה אותי עד הרגע האחרון ממליץ מאוד לכל מי שמתחיל רישיון נהיגה"
                author="שליו החתיך"
              />
            </Grid>
          </Grid>

          <Grid item xs={12} container alignItems="center" direction="column">
            <a
              href={appStoreReviewURL}
              style={{
                alignSelf: "flex-end",
                margin: "10px 0 20px",
                textStyle: "bold",
                color: theme.palette.background.primaryLight,
              }}
              onClick={() => trackOutBoundLink(appStoreReviewURL)}
            >
              עוד...
            </a>
            <Typography
              variant="h3"
              component="h2"
              style={{
                marginTop: "20px",
                color: theme.palette.background.primaryLight,
                textAlign: "center"
              }}>
              נסו את קיצור דרך עכשיו
            </Typography>
            <a
              href={appStoreUrl}
              style={{
                display: "inline-block",
                background:
                  "url(https://linkmaker.itunes.apple.com/en-gb/badge-lrg.svg?releaseDate=2019-01-12&kind=iossoftware&bubble=ios_apps) no-repeat",
                width: "135px",
                height: "40px",
                margin: "30px 30px 40px",
              }}
              onClick={() => trackOutBoundLink(appStoreUrl)}
            />
          </Grid>
        </Container>
      </Paper>
    </Layout>
  )
}

export default IndexPage
